<template>
<div>
    <v-row class="d-flex justify-center mt-0 ">
    <v-col cols="12" class="pb-0">
      <!-- FILTRO PRINCIPAL-->
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <v-expansion-panel-header class="mt-4 py-0 px-4 text-h5" style="cursor: default">
            <div>
              <v-icon class="mb-1" left> fas fa-filter </v-icon>
              Filtro
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1? 0 : 1">
                <v-icon> fas fa-chevron-down </v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1">
            <v-form ref="formFiltroPanel" @submit.prevent="buscar()">
              <v-row class="pt-2">
                <v-col cols="12" sm="6" md="4" class="py-1">
                  Forma de Cobro
                  <v-autocomplete
                    v-model="filtros.formasDeCobro"
                    item-value="forma_cobro_id"
                    item-text="forma_cobro_nombre"
                    :items="formasCobros"
                    hide-details
                    clearable
                    outlined
                    dense
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="py-1">
                  Tarjeta
                  <v-autocomplete
                    v-model="filtros.tarjeta"
                    item-value="tarjeta_codigo"
                    item-text="tarjeta_nombre"
                    :items="tarjetas"
                    hide-details
                    clearable
                    outlined
                    dense
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="py-1">
                  Forma Pago Tarjeta
                  <v-autocomplete
                    v-model="filtros.formaPagoTarjeta"
                    item-value="forma_pago_tar_id"
                    item-text="forma_pago_tar_nombre"
                    :items="formasPagoTarjeta"
                    hide-details
                    clearable
                    outlined
                    dense
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row >
                <v-col cols="12" sm="6" md="4" class="py-1">
                  Liquidación Id
                  <v-text-field
                    v-model.trim="filtros.liqId"
                    type="number"
                    hide-details
                    clearable
                    outlined
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="py-1">
                  Nro. Resumen
                  <v-text-field
                    v-model.trim="filtros.nroResumen"
                    type="number"
                    hide-details
                    clearable
                    outlined
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="py-1">
                  Nro. Comercio
                  <v-text-field
                    v-model.trim="filtros.nroComercio"
                    type="number"
                    hide-details
                    clearable
                    outlined
                    dense
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="pt-3">
                <v-col cols="7">
                  <v-row>
                    <v-col cols="4" class="py-0">
                      Fecha desde
                      <FechaPicker
                        v-model="filtros.fechaDesde"
                        :clearable="true"
                        :hideDetails="true"
                      />
                    </v-col>
                    <v-col cols="4" class="py-0">
                      Fecha hasta
                      <FechaPicker
                        v-model="filtros.fechaHasta"
                        :clearable="true"
                        :hideDetails="true"
                      />
                    </v-col>
                    <v-col cols="4" class="py-0">
                      Mov. Bancario
                      <v-text-field
                        v-model.trim="filtros.movBancario"
                        hide-details
                        clearable
                        outlined
                        dense
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="5" class="d-flex justify-end">
                  <BtnFiltroVue
                    :loading="load"
                    clase="mt-0"
                    @clear="limpiar()"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
    <v-col cols="12">
      <v-data-table
        class="cebra elevation-2 mt-2"
        :headers="headers"
        :items="datosLiquidaciones"
        :loading="load"
        :search="search"
        :footer-props="{'items-per-page-options':[10, 15, 50, 100]}"
        dense
        @contextmenu:row="clickDerecho"
        :item-class="ponerBold"
        item-key="id"
        multi-sort
      >
        <template v-slot:top>
          <v-row class="d-flex justify-end pa-2" no-gutters>
            <v-col cols="6" sm="3" md="2">
              <SearchDataTableVue
                v-model="search"
              />
            </v-col>
          </v-row>
        </template>
        <template v-slot:no-data>
          <v-alert
            class="mx-auto mt-4"
            max-width="400"
            type="warning"
            border="left"
            dense
            text
          >
            No hay datos para los filtros seleccionados
          </v-alert>
        </template>
        <template v-slot:[`item.accion`]="{ item }">
          <v-col cols="3">
            <v-icon
              color="info"
              title="Ver Liquidación"
              small
              @click="open_liquidacion(item)"
            >
              fas fa-eye
            </v-icon>
          </v-col>
        </template>
        <!-- Permite aplicar formateos a plata a todas las columnas del dataTable  -->
        <template
          v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
          v-slot:[`item.${header.value}`]="{ value }"
        >
          {{ header.formatter(value) }}
        </template>
      </v-data-table>
    </v-col>
    <!-- Menu del Click derecho -->
    <v-menu
      v-model="menu.activo"
      :position-x="menu.x"
      :position-y="menu.y"
      absolute
      offset-y
    >
      <v-list>
        <v-list-item @click="open_liquidacion(liqAcual)">
          <v-list-item-title>
            <v-icon color="info" small left> fas fa-eye </v-icon>
            Ver detalles
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="generarMovBancario(liqAcual)">
          <v-list-item-title>
            <v-icon color="success" small left> fas fa-landmark </v-icon>
            Generar Movimiento Pago
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="anularMovBancario(liqAcual)">
          <v-list-item-title>
            <v-icon color="deep-orange" small left> fas fa-list-ul </v-icon>
            Anular movimientos bancarios
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="eliminarLiquidacion(liqAcual)">
          <v-list-item-title>
            <v-icon color="error" small left> fas fa-trash </v-icon>
            Eliminar Liquidación
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="conciliarLiquidacionTodo(liqAcual)">
          <v-list-item-title>
            <v-icon color="purple" small left> fas fa-compress-alt </v-icon>
            Conciliar Liquidación
          </v-list-item-title>
        </v-list-item>
        <v-list-item  @click="open_modalSubirGral(liqAcual)">
          <v-list-item-title>
            <v-icon :color="con_archivo > 0 ? 'success' : 'warning'" small left> fas fa-cloud-upload-alt </v-icon>
            Subir Archivos
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-row>
  <ModalMovBancLiqVue
    v-model="dialogMov"
    :datos="movBancLiqObj"
  />
      <!-- Modal Subir Archivos -->
  <ModalUploadImgGralVue 
    :datos="dialogSubirImgs"
    @setearModalUpImg="setModalUpImg"
    :p_nombre="dialogSubirImgs.id.toString()"
  />
</div>
</template>

<script>
import FechaPicker from '../../components/util/FechaPicker.vue'
import BtnFiltroVue from '../../components/util/BtnFiltro.vue'
import SearchDataTableVue from '../../components/util/SearchDataTable.vue'
import { format_money, objetoNoVacio, cadenaVacia, roundNumber } from '../../util/utils'
import ModalMovBancLiqVue from '../../components/auditoria/ModalMovBancLiq.vue';
import ModalUploadImgGralVue from '../../components/generales/ModalUploadImgGral.vue'
import moment from 'moment'

export default {
  name: 'IndexConciliacionManual',
  data(){
    return{
      moment: moment,
      panel: 0,
      search: '',
      load: false,
      formatMoney: format_money,
      //Menu click derecho
      menu: {
        activo: false,
        x: 0,
        y: 0
      },
      liqAcual:{},
      liqAcualId: null,
      //Filtros del panel general
      filtros:{
        formasDeCobro: null,
        tarjeta: null,
        formaPagoTarjeta: null,
        liqId: null,
        nroResumen: null,
        nroComercio: null,
        fechaDesde: null,
        fechaHasta: null,
        movBancario: null
      },
      //initConciliacion
      formasCobros:[], //si ver = 0
      tarjetas:[],
      formasPagoTarjeta: [],
      tiposPedidos:[], //si ver = 1
      formasPago:[],
      descuentos:[],
      //DataTable
      headers:[
        { text: '', value: 'accion', filtreable: false, sorteable: false},
        { text: 'ID', value: 'id'},
        { text: 'F. Cobro', value: 'forma_cobro_nombre'},
        { text: 'Tarjeta', value: 'tarjeta_nombre'},
        { text: 'N° Res.', value: 'nro_resumen'},
        { text: 'Fecha Pago', value: 'fecha_pago'},
        { text: 'T. Bruto', value: 'total_bruto', align: 'end',formatter: format_money},
        { text: 'Dif.', value: 'bb_vs_tarjeta', align: 'end' ,formatter: format_money},
        { text: 'Total', value: 'total_bb', align: 'end', formatter: format_money},
        { text: 'Desc.', value: 'descuentos', align: 'end', formatter: format_money},
        { text: 'T. Neto', value: 'total_neto', align: 'end',formatter: format_money},
        { text: 'Conc.', value: 'conciliados'},
        { text: 'No Conc.', value: 'no_conciliados'},
        { text: 'Mov. Banc.', value: 'con_mov_bancario'},
        { text: 'Comerc.', value: 'nro_comercio'},
        
      ],
      datosLiquidaciones: [],
      con_archivo: null,
      //paraMovMultiples-sola1
      dialogMov: false,
      movBancLiqObj: {},
      //modal subir Gral
      dialogSubirImgs: {
        activo: false,
        icono: 'fas fa-eye',
        titulo_modal: '',
        tipos: ['.jpg', '.png', '.webp', '.pdf'], // formatos admitidos
        multiple: true,
        max_size: 1, // tamaño del archivo en megas
        ruta: '', // ruta del archivo
        raiz: 0, // indica si vamos a trabajar con la raiz del 10.40 o la de jarvis 2
        tabla: 10, // indica la tabla donde va el log
        id: 0, // generalmente indica un numero identificador para la tabla del log, por ejemplo para facturas de compra seria el id de la factura de compra en la tabla LOG_FACTURAS_COMPRAS en el campo FACTURA_COMPRA_ID
        permiso_borrar: 1, // permiso para borrar archivos
        parametros: {mini: 0}
      },
    }
  },
  created(){
    this.iniciarIndex();
  },
  methods:{
    async iniciarIndex(){
      this.$store.state.loading = true;
      await this.$store.dispatch('conciliacionManual/initConciliacionManual',0)
        .then((res) => {
          this.formasCobros = res.formas_cobro;
          this.tarjetas = res.tarjetas;
          this.formasPagoTarjeta = res.formas_pago_tarjeta;
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false;
    },
    async buscar(){
      if(this.filtros.formasDeCobro || this.filtros.tarjeta || this.filtros.formaPagoTarjeta || 
         this.filtros.liqId || this.filtros.nroResumen || this.filtros.nroComercio){
        if(!this.filtros.fechaDesde && !this.filtros.movBancario && !this.filtros.liqId){ //y si no ingresaron Fecha desde, liquidación id ni mov bancario
          this.$store.dispatch('show_snackbar', {
            text: 'Para filtrar se debe seleccionar una Fecha desde o ingresar un Mov. Bancario',
            color: 'warning'
          })
          return
        }
      }
      if(!this.filtros.movBancario && !this.filtros.fechaDesde && !this.filtros.liqId){ //si no ingresaron Mov Bancario //y si no ingresaron Fecha desde
        this.$store.dispatch('show_snackbar', {
          text: 'Para filtrar se debe al menos seleccionar una Fecha desde',
          color: 'warning'
        })
        return
      }
      this.load = true;
      this.datosLiquidaciones = [];
      await this.$store.dispatch('conciliacionManual/searchLiquidaciones',{
        filtro: this.filtros,
        ver: 0
      })
      .then((res) => {
        this.datosLiquidaciones = res.liquidaciones;
      })
      .catch(error => {
        this.$store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      })
      this.search='';
      this.load = false;
    },
    limpiar(){
      this.search='';
      this.datosLiquidaciones = [];
      this.filtros={
        formasDeCobro: null,
        tarjeta: null,
        formaDeTarjeta: null,
        liqId: null,
        nroResumen: null,
        nroComercio: null,
        fechaDesde: null,
        fechaHasta: null,
        movBancario: null
      }
    },
    open_liquidacion(item){
      const routeData = this.$router.resolve({path: `/conciliacion-manual/${item.id}`})
      window.open(routeData.href, '_blank')
    },
    clickDerecho(event, item){
      event.preventDefault()
      this.liqAcual = {}
      this.menu.activo = false
      this.menu.x = event.clientX
      this.menu.y = event.clientY
      this.$nextTick(() => {
        this.menu.activo = true
        this.liqAcualId = item.item.id;
        this.liqAcual = item.item;
        this.con_archivo = item.item.con_archivo;
      })
    },
    ponerBold(item){
      return item.id === this.liqAcualId ? ['font-weight-bold'] : '';
    },
    generarMovBancario(liq){
      this.$swal.fire({
        icon: 'warning',
        title: `Está por generar el Pago para la Liquidación ${liq.id}. ¿Confirma esta accion?`,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
      }).then((res) => {
        if(res.isConfirmed){
          this.$swal.fire({
            icon: 'info',
            title: 'Escoja el tipo de Movimiento a generar',
            showCloseButton: true,
            showCancelButton: false,
            showDenyButton: true,
            confirmButtonText: 'Múltiples Mov. Bancarios',
            denyButtonText: 'Un solo Movimiento Bancario',
          }).then(async  (result) => {
            if(result.isConfirmed){ //abre el modal
              this.movBancLiqObj.liqId = liq.id;
              this.movBancLiqObj.totalNeto = liq.total_neto
              this.dialogMov = true;
            }
            if(result.isDenied){
              if(liq.con_mov_bancario == 'NO'){
                this.$store.state.loading = true
                await this.$store.dispatch('conciliacionManual/generarUnMovBancario',{
                  liquidacion_id: liq.id
                })
                  .then((res) => {
                    this.$swal.fire({icon: 'success', title: 'Existoso', text: 'Se generó el movimiento exitosamente.',})
                    let i=0;
                    while(i < this.datosLiquidaciones.length && this.datosLiquidaciones[i].id != liq.id){
                      i++;
                    }
                    if(i < this.datosLiquidaciones.length){
                      this.datosLiquidaciones[i].con_mov_bancario = res.movimiento_codigo;
                    }
                  })
                  .catch(error => {
                    this.$store.dispatch('show_snackbar', {
                      text: error.message,
                      color: 'error'
                    })
                  })
                this.$store.state.loading = false
              }else{
                this.$swal.fire({
                  icon: 'error',
                  title: `Error al Generar el Pago de la Liquidacion`,
                  text: 'La liquidación ya cuenta con movimientos bancarios asociados. No se puede proceder',
                })
              }
            }
          })
        }
      })
    },
    anularMovBancario(liq){
      this.$swal.fire({
        icon: 'warning',
        title: `Esta eliminando el Pago para la Liquidación ${liq.id}. ¿Confirma esta accion?`,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
      }).then(async (res) => {
        if(res.isConfirmed){
          //if(liq.mov_bancario != '' && liq.mov_bancario != null){ //si tiene mov_bancario, entonces elimina o anula
            this.$store.state.loading = true
            await this.$store.dispatch('conciliacionManual/anularMovsBancarios',{
              liquidacion_id: liq.id
            })
              .then(() => {
                this.$swal.fire({icon: 'success', title: 'Existoso', text: 'Se anuló el movimiento exitosamente.',})
                //con_mov_bancario == 'NO'
                let i=0;
                while(i < this.datosLiquidaciones.length && this.datosLiquidaciones[i].id != liq.id){
                  i++;
                }
                if(i < this.datosLiquidaciones.length){
                  this.datosLiquidaciones[i].con_mov_bancario = 'NO';
                }
              })
              .catch(error => {
                this.$store.dispatch('show_snackbar', {
                  text: error.message,
                  color: 'error'
                })
              })
            this.$store.state.loading = false
          //}else{
            /*this.$swal.fire({
              icon: 'error',
              title: `Error al anular el Movimiento Bancario`,
              text: 'La liquidación no cuenta con un movimiento bancario.',
            })*/
          //}
        }
      })
    },
    async eliminarLiquidacion(liq){
      this.$swal.fire({
        icon: 'warning',
        title: `Esta eliminando la Liquidación ${liq.id}. ¿Confirma esta accion?`,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
      }).then(async (res) => {
        if(res.isConfirmed){
          if(liq.con_mov_bancario == 'NO'){
            this.$store.state.loading = true
            await this.$store.dispatch('conciliacionManual/borrarLiquidacion',{
              liquidacion_id: liq.id
            })
              .then(() => {
                this.$swal.fire({icon: 'success', title: 'Existoso', text: 'Se borró la liquidación exitosamente.',})
              })
              .catch(error => {
                this.$store.dispatch('show_snackbar', {
                  text: error.message,
                  color: 'error'
                })
              })
            this.$store.state.loading = false
          }else{
            this.$swal.fire({
              icon: 'error',
              title: `Error al eliminar la Liquidacion`,
              text: 'Se tienen operaciones Conciliadas o movimientos bancarios asociados, no se puede eliminar',
            })
          }
        }
      })
    },
    conciliarLiquidacionTodo(liq){
      this.$swal.fire({
        icon: 'warning',
        title: `Esta conciliando la Liquidación ${liq.id}. ¿Confirma esta accion?`,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
      }).then(async (res) => {
        if(res.isConfirmed){
          this.$store.state.loading = true
          await this.$store.dispatch('conciliacionManual/conciliarTodo',{
            liquidacion_id: liq.id
          })
            .then(async () => {
              this.$swal.fire({icon: 'success', title: 'Existoso', text: 'Conciliación exitosa.',})
              await this.$store.dispatch('conciliacionManual/searchLiquidaciones',{
                filtro: this.filtros,
                ver: 0
              })
              .then((res) => {
                this.datosLiquidaciones = res.liquidaciones;
              })
              .catch(error => {
                this.$store.dispatch('show_snackbar', {
                  text: error.message,
                  color: 'error'
                })
              })
            })
            .catch(error => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error'
              })
            })
          this.$store.state.loading = false
        }
      })
    },
    open_modalSubirGral(liq){
      this.$store.state.loading = true;
      //if(band == 1){ //Grandes
        //titulo modal
        this.dialogSubirImgs.titulo_modal = `Imágenes para la liquidación ${liq.id}`;
        //ruta de carpeta
        this.dialogSubirImgs.ruta = '/conciliacion_manual_archivos/' + liq.id;
        //id
        this.dialogSubirImgs.id = liq.id;
        this.dialogSubirImgs.parametros.mini = 0;
        //Abrir modal
        this.dialogSubirImgs.activo = true
        this.dialogSubirImgs.parametros = {};
        
      /*}else{//Miniatura
        //titulo modal
        this.dialogSubirImgs.titulo_modal = 'Imágenes miniatura para el Artículo ' + this.datosFiltrados.codigoArticulo;
        //ruta de carpeta
        this.dialogSubirImgs.ruta = '/acc_imagenes_mini/' + this.datosFiltrados.codigoArticulo;
        //id
        this.dialogSubirImgs.id = this.datosFiltrados.codigoArticulo;
        this.dialogSubirImgs.parametros.mini = 1;
              //Abrir modal
        this.dialogSubirImgs.activo = true
        
      }*/
      this.$store.state.loading = false;
    },
    setModalUpImg(value){
      this.dialogSubirImgs.activo = value;
    },
  },
  components:{
    FechaPicker, BtnFiltroVue, SearchDataTableVue, ModalMovBancLiqVue, ModalUploadImgGralVue
  },
  watch:{
    'menu.activo':{
      handler: function(val){
        val||(this.liqAcualId = null);
      },
      deep: true
    }
  }
}
</script>

<style>

</style>